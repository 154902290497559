import React, { useState } from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  label: string;
  placeholder: string;
  options: Option[];
  required?: boolean;
  isMulti?: boolean; // New prop to indicate multi-select
  selectedValues?: string[]; // New prop to receive selected values from the parent component
  onChange?: (selectedOptions: string[]) => void; // New prop to handle changes
  id: string;
  name: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  options,
  required = false,
  isMulti = false,
  selectedValues = [],
  onChange,
  id,
  name,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | string[]>(
    isMulti ? selectedValues : ""
  );

  const handleChange = (value: string) => {
    if (isMulti) {
      // Handle multi-select
      const updatedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];

      setSelectedValue(updatedValues);
      onChange && onChange(updatedValues); // Call the parent onChange with updated values
    } else {
      // Handle single-select
      setSelectedValue(value);
      onChange && onChange([value]);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2 text-[#797979]">
        {label} {required && "*"}
      </label>
      {isMulti ? (
        <div className="flex flex-col space-y-2">
          {options.map((option) => (
            <label key={option.value} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => handleChange(option.value)}
                className="form-checkbox text-indigo-600"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      ) : (
        <select
          value={typeof selectedValue === "string" ? selectedValue : ""}
          onChange={(e) => handleChange(e.target.value)}
          className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
            selectedValue === "" ? "text-gray-400" : "text-black"
          }`}
          id={id}
          name={name}
        >
          <option value="" disabled className="text-gray-400">
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Select;
