import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { IonContent, IonItem, IonLabel, IonList, IonMenu } from "@ionic/react";

import { connect } from "../data/connect";
import "./Menu.css";
import RenderMenuListItems from "./RenderMenuListItems";
import { routes } from "../Routes";

export interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
  isLaravelPage?: boolean;
}

interface StateProps {
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface MenuProps extends RouteComponentProps, StateProps {}

const Menu: React.FC<MenuProps> = ({ menuEnabled }) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const apiUrl = "/papi/user";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((responseData) => {
        setData(responseData);
      })
      .catch((err) => {
        console.error("Error while fetching data from the API.", err);
      });
  }, []);

  return (
    <IonMenu
      type="overlay"
      className="width-fit-content"
      disabled={!menuEnabled}
      contentId="main"
      id="sidebar-menu"
    >
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <RenderMenuListItems
            list={
              data
                ? data.role === "instructor"
                  ? routes.appInstructorMenu
                  : data.role === "student"
                  ? routes.appStudentMenu
                  : routes.UserNotLoggedInButRoleNotSelectedMenu
                : routes.UserNotLoggedInMenu
            }
            data={data}
          />
        </IonList>
        <IonList lines="none">
          {/* temporarily disabling these menu items, will enable it for android version. */}
          {/* <IonListHeader>Account</IonListHeader>
        {isAuthenticated
          ? renderlistItems(routes.loggedInPages)
          : renderlistItems(routes.loggedOutPages)} */}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled,
  }),
  mapDispatchToProps: {},
  component: withRouter(Menu),
});
