import React from "react";

interface InputProps {
  label: string;
  placeholder: string;
  value: string; // Accept the input value as a prop
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Accept an onChange handler
  required?: boolean;
  id: string;
  name: string;
}

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  required = false,
  id,
  name,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2 text-[#797979]">
        {label} {required && "*"}
      </label>
      <input
        type="text"
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
      />
    </div>
  );
};

export default Input;
