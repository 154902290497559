import React, { useEffect, useState } from "react";

import { IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";

import { LoadPracticeSessions } from "../papiclient/client";
import { PracticeSessionListComponent } from "../components/PracticeSession/List";
import {
  PapiPracticeSessionsGETResponse,
  PracticeSessionListInfoItem,
} from "../models/PracticeSession";
import { setDarkMode } from "../data/user/user.actions";
import { connect } from "../data/connect";

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
}
interface PracticeSessionListPage extends StateProps, DispatchProps {}

const PracticeSessionListPage: React.FC<PracticeSessionListPage> = ({
  darkMode,
}) => {
  const [psList, setPsList] = React.useState<
    PracticeSessionListInfoItem[] | undefined
  >();
  const [renderTrigger, setRenderTrigger] = useState(0); // Add a render trigger

  useEffect(() => {
    loadSessions();
  }, []);

  const loadSessions = () => {
    LoadPracticeSessions()
      .then((pss: PracticeSessionListInfoItem[]) => setPsList(pss))
      .catch((reason) => console.log(reason));
  };

  const onPracticeCreated = async () => {
    const newSessions = await LoadPracticeSessions();
    setPsList(newSessions);
    setRenderTrigger((prev) => prev + 1); // Increment render trigger
  };

  return (
    <IonPage id="practice-session-page">
      {psList === undefined ? (
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonTitle>Loading ...</IonTitle>
          </IonToolbar>
        </IonHeader>
      ) : (
        <PracticeSessionListComponent
          key={renderTrigger}
          darkMode={darkMode}
          psList={psList}
          onPracticeCreated={onPracticeCreated}
        />
      )}
    </IonPage>
  );
};

export default connect<PracticeSessionListPage, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: PracticeSessionListPage,
});
