import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Input from "./Input";
import Select from "./Select";
import Button from "./Button";
import ExamSelect from "./ExamSelect";
import { useHistory } from "react-router";
import { debounce } from "lodash";
import swal from "sweetalert";

interface ICreatePracticeForm {
  closeModal: () => void;
  onPracticeCreated: () => Promise<void>; // Indicates that this function is asynchronous and returns a promise
}

interface Topic {
  id: string;
  name: string;
}

interface Exam {
  id: string;
  name: string;
}

interface Wordlist {
  id: string;
  name: string;
}

interface LearningMode {
  id: string;
  name: string;
}

const CreatePracticeForm: React.FC<ICreatePracticeForm> = ({
  closeModal,
  onPracticeCreated,
}) => {
  const [questions, setQuestions] = useState(5);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [exams, setExams] = useState<Exam[]>([]);
  const [wordlists, setWordlists] = useState<Wordlist[]>([]);
  const [learningModes, setLearningModes] = useState<LearningMode[]>([]);
  const [availableQuestions, setAvailableQuestions] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [selectedExams, setSelectedExams] = useState<string[]>([]);
  const [selectedWordlist, setSelectedWordlist] = useState<string | null>(null);
  const [selectedMode, setSelectedMode] = useState<string | null>(null);
  const [practiceName, setPracticeName] = useState<string>("");
  const [wordPercentage, setWordPercentage] = useState<number>(5); // Default to 5%
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const history = useHistory();

  // Function to fetch topics dynamically
  const getTopics = async (): Promise<Topic[]> => {
    try {
      const response = await fetch("/papi/institution/topic");
      const data = await response.json();
      return response.ok && data.result
        ? data.result.map((topic: any) => ({ id: topic.id, name: topic.name }))
        : [];
    } catch (error) {
      console.error("Error while fetching topics:", error);
      return [];
    }
  };

  // Function to fetch exams dynamically
  const getExams = async (): Promise<Exam[]> => {
    try {
      const response = await fetch("/papi/institution/exam");
      const data = await response.json();
      return response.ok && data.result
        ? data.result.map((exam: any) => ({ id: exam.id, name: exam.name }))
        : [];
    } catch (error) {
      console.error("Error while fetching exams:", error);
      return [];
    }
  };

  // Function to fetch wordlists dynamically
  const getWordlists = async (): Promise<Wordlist[]> => {
    try {
      const response = await fetch("/papi/institution/wordlist");
      const data = await response.json();
      return response.ok && data
        ? data.map((wordlist: any) => ({
            id: wordlist.id,
            name: wordlist.name,
          }))
        : [];
    } catch (error) {
      console.error("Error while fetching wordlists:", error);
      return [];
    }
  };

  const getLearningModes = async (): Promise<LearningMode[]> => {
    try {
      const response = await fetch(
        "/papi/institution/practice-session/learning-mode",
        {
          method: "GET", // Ensure the method is GET
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Transform array of strings into objects with id and name
        return (
          data.learningModes &&
          data.learningModes.map((mode: string, index: number) => ({
            id: index.toString(), // Assigning an ID based on the index
            name: mode, // The mode itself is the name
          }))
        );
      } else {
        console.error("Error:", data);
        return [];
      }
    } catch (error) {
      console.error("Error while fetching learning modes:", error);
      return [];
    }
  };

  // UseEffect to fetch data on component mount
  useEffect(() => {
    const fetchTopics = async () => {
      const fetchedTopics = await getTopics();
      setTopics(fetchedTopics);
    };
    const fetchExams = async () => {
      const fetchedExams = await getExams();
      setExams(fetchedExams);
    };
    const fetchWordlists = async () => {
      const fetchedWordlists = await getWordlists();
      setWordlists(fetchedWordlists);
    };
    const fetchLearningModes = async () => {
      const fetchedLearningModes = await getLearningModes();
      setLearningModes(fetchedLearningModes);
    };

    fetchTopics();
    fetchExams();
    fetchWordlists();
    fetchLearningModes();
  }, []);

  const handleCreatePractice = async (startImmediately: boolean = false) => {
    setErrors({});

    const numberOfWords = Math.round((questions * wordPercentage) / 100);

    try {
      const requestBody = {
        name: practiceName,
        numberOfQuestions: questions,
        numberOfWords: numberOfWords,
        examIds: selectedExams.map(Number),
        topicId: selectedTopic ? Number(selectedTopic) : undefined,
        wordlistId: selectedWordlist ? Number(selectedWordlist) : undefined,
        learningMode: selectedMode,
        practiceTemplateId: 0,
      };

      // Proceed with the fetch request if confirmed
      const response = await fetch(
        "/papi/institution/student/practice-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessages = errorData.message.split("|");

        // Parse and set errors for specific fields
        const fieldErrors: { [key: string]: string } = {};
        let genericError =
          "There were errors in your submission; please try again.";

        errorMessages.forEach((err: string) => {
          if (err.includes("Name") || err.includes("already been taken")) {
            if (err.includes("already been taken")) {
              fieldErrors["practiceName"] = "The name has already been taken.";
            } else {
              fieldErrors["practiceName"] = "Name is required.";
            }
          }

          if (err.includes("ExamIDs")) {
            fieldErrors["selectedExams"] = "Exam is required.";
          }

          if (err.includes("TopicID")) {
            fieldErrors["selectedTopics"] = "Topic is required.";
          }

          if (err.includes("LearningMode")) {
            fieldErrors["selectedMode"] = "Learning mode is required.";
          }
        });
        // If no specific errors are identified, set the generic error
        if (Object.keys(fieldErrors).length === 0) {
          fieldErrors["generic"] = genericError;
        }
        setErrors(fieldErrors);
        // Show SweetAlert with the generic error message
        await swal({
          title: "Failed to create practice",
          text: genericError,
          icon: "error",
        });

        console.error("Error while creating practice session:", errorData);
        return;
      }

      const responseData = await response.json();
      const practiceSessionId = responseData.practiceSessionId;
      if (startImmediately) {
        onPracticeCreated().then(() => {
          if (practiceSessionId) {
            history.push(`/PracticeSession/Start/${practiceSessionId}`);
          }
          closeModal();
        });
      } else {
        onPracticeCreated().then(() => {
          closeModal();
        });
      }
    } catch (error) {
      setErrors({ generic: "An unexpected error occurred. Please try again." });
      console.error("Error while creating practice session:", error);
    }
  };

  // Debounce the fetchQuestionCount function
  const debouncedFetchQuestionCount = debounce(async () => {
    try {
      const requestBody = {
        topicId: Number(selectedTopic),
        examIds: selectedExams.map(Number),
      };

      const response = await fetch(
        "/papi/institution/practice-session/question-count",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (response.ok && data.count !== undefined) {
        setAvailableQuestions(data.count);
      } else {
        setAvailableQuestions(0);
        console.error("Error while fetching question count:", data);
      }
    } catch (error) {
      setAvailableQuestions(0);
      console.error("Error while fetching question count:", error);
    }
  }, 300); // Adjust the debounce delay as needed

  // useEffect to call debounced function on topic or exam change
  useEffect(() => {
    debouncedFetchQuestionCount();
    // Cleanup debounce on unmount
    return () => debouncedFetchQuestionCount.cancel();
  }, [selectedTopic, selectedExams]);

  useEffect(() => {
    if (questions === undefined || questions === null) {
      setQuestions(5); // set only if questions is initially undefined
    }
  }, []);

  // Function to handle word percentage increment and decrement
  const incrementWordPercentage = () =>
    setWordPercentage((prev) => Math.min(prev + 5, 100)); // Maximum 100%
  const decrementWordPercentage = () =>
    setWordPercentage((prev) => Math.max(prev - 5, 5)); // Minimum 5%

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center p-4 overflow-hidden"
      onClick={closeModal} // Closes modal when clicking outside the form
    >
      <div
        className="bg-white p-4 md:p-6 rounded-lg shadow-md w-full max-w-md md:max-w-lg relative max-h-[95vh] overflow-y-auto no-scrollbar"
        onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
      >
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-sm md:text-2xl font-semibold text-[#797979]">
            Create Your Practice
          </h2>
          <button
            onClick={closeModal}
            className="px-2 py-1 text-gray-600 hover:text-gray-900 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        {/* Modal Body */}
        <div className="space-y-4">
          <Input
            label="Name"
            placeholder="Name of your practice..."
            id="name"
            name="name"
            value={practiceName}
            onChange={(e) => setPracticeName(e.target.value)}
            required
          />
          {errors.practiceName && (
            <p className="text-red-500 text-sm">{errors.practiceName}</p>
          )}

          <Select
            label="Topic"
            placeholder="Choose Topic"
            id="topic_id"
            name="topic_id"
            options={topics.map((topic) => ({
              value: topic.id,
              label: topic.name,
            }))}
            selectedValues={selectedTopic ? [selectedTopic] : []}
            onChange={(updatedTopic) => {
              setSelectedTopic(updatedTopic[0] || "");
            }}
            required
          />
          {errors.selectedTopic && (
            <p className="text-red-500 text-sm">{errors.selectedTopic}</p>
          )}

          <ExamSelect
            exams={exams}
            selectedExams={selectedExams}
            setSelectedExams={setSelectedExams}
            id="exam_id"
            name="exam_id[]"
            classNamePrefix="exam_select"
          />
          {errors.selectedExams && (
            <p className="text-red-500 text-sm">{errors.selectedExams}</p>
          )}

          {/* Display available questions based on the selected topic and exam */}
          <p className="text-gray-600 text-sm qus_help_msg" id="qus_help_msg">
            There are <b>{availableQuestions}</b> Question(s) in this topic and
            exam(s).
          </p>

          <div className="flex items-center justify-start gap-3 my-4">
            <label className="text-sm font-medium text-[#797979]">
              Total Questions *
            </label>
            <div className="flex items-center">
              <button
                id="totalQuestionsIncrement"
                name="totalQuestionsIncrement"
                onClick={() => setQuestions((prev) => Math.min(prev + 5, 100))}
                className="px-2 py-1 bg-purple-500 text-white rounded-l-md focus:outline-none"
              >
                +
              </button>
              <input
                type="text"
                className="spinner-input form-control px-4 py-2 bg-gray-100"
                value={questions}
                name="numQuestions"
                id="numQuestions"
                onChange={(e) => setQuestions(Number(e.target.value) || 0)} // Update state directly
              />
              <button
                id="totalQuestionsDecrement"
                name="totalQuestionsDecrement"
                onClick={() => setQuestions((prev) => Math.max(prev - 5, 5))}
                className="px-2 py-1 bg-pink-500 text-white rounded-r-md focus:outline-none"
              >
                -
              </button>
            </div>
          </div>

          <p className="text-sm text-gray-600">
            Beware that the longer the session, the more stressful it is. We
            want you to also have fun! So, do not make it more than 50
            questions.
          </p>

          <Select
            label="Wordlist"
            placeholder="Choose Wordlist"
            id="wordlist_id"
            name="wordlist_id"
            options={wordlists.map((wordlist) => ({
              value: wordlist.id,
              label: wordlist.name,
            }))}
            selectedValues={selectedWordlist ? [selectedWordlist] : []}
            onChange={(updatedWordlist) => {
              setSelectedWordlist(updatedWordlist[0] || null);
            }}
          />

          {selectedWordlist && (
            <div className="my-4">
              <div className="flex items-center justify-star gap-3">
                <label className="text-sm font-medium text-[#797979]">
                  Word Percentage (%) *
                </label>
                <div className="flex items-center my-2">
                  <button
                    id="wordPercentageIncrement"
                    name="wordPercentageIncrement"
                    onClick={incrementWordPercentage}
                    className="px-2 py-1 bg-purple-500 text-white rounded-l-md focus:outline-none"
                  >
                    +
                  </button>
                  <input
                    type="text"
                    className="spinner-input form-control px-4 py-2 bg-gray-100"
                    value={wordPercentage}
                    name="numWordPercentage"
                    id="numWordPercentage"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        // Allow only numeric characters
                        setWordPercentage(Number(value) || 0); // Update the state
                      }
                    }}
                  />

                  <button
                    id="wordPercentageDecrement"
                    name="wordPercentageDecrement"
                    onClick={decrementWordPercentage}
                    className="px-2 py-1 bg-pink-500 text-white rounded-r-md focus:outline-none"
                  >
                    -
                  </button>
                </div>
              </div>
              <p className="text-gray-600 text-sm">
                Your word questions will be{" "}
                {Math.round((questions * wordPercentage) / 100)} out of{" "}
                {questions} questions.
              </p>
            </div>
          )}

          <Select
            label="Learning Mode"
            placeholder="Choose Learning Mode"
            id="learning_mode"
            name="learning_mode"
            options={learningModes.map((mode) => ({
              value: mode.name,
              label: mode.name,
            }))}
            selectedValues={selectedMode ? [selectedMode] : []}
            onChange={(updatedMode) => {
              setSelectedMode(updatedMode[0] || null);
            }}
            required
          />
          {errors.selectedMode && (
            <p className="text-red-500 text-sm">{errors.selectedMode}</p>
          )}
        </div>

        {/* Modal Footer */}
        <div className="flex justify-between space-x-2 mt-6">
          <Button
            id="createPracticeBtn"
            name="createPracticeBtn"
            label="Create Your Practice"
            color="blue"
            className="flex-grow text-xs md:text-[0.9rem]"
            onClick={() => handleCreatePractice(false)}
          />
          <Button
            id="createAndStartPracticeBtn"
            name="createAndStartPracticeBtn"
            label="Create & Start Practice"
            color="green"
            className="flex-grow text-xs md:text-[0.9rem]"
            onClick={() => handleCreatePractice(true)}
          />
          <Button
            id="cancelBtn"
            name="cancelBtn"
            label="Cancel"
            color="#dae6ec4d"
            className="flex-grow text-xs md:text-[0.9rem]"
            onClick={closeModal}
          />
        </div>
        {errors.generic && (
          <p className="text-red-500 text-sm">{errors.generic}</p>
        )}
      </div>
    </div>,
    document.body
  );
};

export default CreatePracticeForm;
