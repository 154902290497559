import React from "react";

interface ButtonProps {
  label: string;
  color: string;
  className?: string; // Allow custom class names
  onClick?: () => void; // Add the onClick function prop
  id: string;
  name: string;
}

const Button: React.FC<ButtonProps> = ({
  label,
  color,
  className,
  onClick,
  id,
  name,
}) => {
  const getColorClasses = (color: string) => {
    switch (color) {
      case "blue":
        return "bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white";
      case "green":
        return "bg-green-500 hover:bg-green-600 focus:ring-green-400 text-white";
      case "#dae6ec4d":
        return "bg-[#dae6ec4d] hover:bg-[#dae6ec4d] focus:ring-gray-400 text-[#333]";
      default:
        return "bg-[#dae6ec4d] hover:bg-[#dae6ec4d] focus:ring-gray-400 border-[#333]";
    }
  };

  return (
    <button
      className={`${getColorClasses(
        color
      )}  px-4 py-[0.5rem] rounded-md focus:outline-none focus:ring-2 ${className}`}
      onClick={onClick} // Corrected the onClick handler
      id={id}
      name={name}
    >
      {label}
    </button>
  );
};

export default Button;
