import React from "react";
import Select, { MultiValue } from "react-select";

interface ExamOption {
  value: string;
  label: string;
}

interface ExamSelectProps {
  exams: { id: string; name: string }[];
  selectedExams: string[]; // Array of selected exam IDs
  setSelectedExams: (selectedExams: string[]) => void; // Function to update selected exams
  id: string;
  name: string;
  classNamePrefix: string;
}

const ExamSelect: React.FC<ExamSelectProps> = ({
  exams,
  selectedExams,
  setSelectedExams,
  classNamePrefix,
  id,
  name,
}) => {
  const examOptions: ExamOption[] = exams.map((exam) => ({
    value: exam.id,
    label: exam.name,
  }));

  const handleChange = (selectedOptions: MultiValue<ExamOption>) => {
    const updatedExams = selectedOptions.map((option: any) => option.value);
    setSelectedExams(updatedExams);
  };

  return (
    <Select
      isMulti
      options={examOptions}
      value={examOptions.filter((option) =>
        selectedExams.includes(option.value)
      )}
      onChange={handleChange}
      placeholder="Choose related exams..."
      id={id}
      name={name}
      classNamePrefix={classNamePrefix}
    />
  );
};

export default ExamSelect;
