import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { copy, newspaperOutline, play } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { PracticeSessionListInfoItem } from "../../models/PracticeSession";
import Pagination from "../CustomPagination/Pagination";
import "./list.css";
import "./PracticeSession.css";
// @ts-ignore
import Icon from "@mdi/react";
// @ts-ignore
import { mdiViewDashboard } from "@mdi/js";
import CreatePracticeForm from "./CreatePracticeForm/CreatePracticeForm";

type actionsProps = {
  psInfo: PracticeSessionListInfoItem;
};

export const ActionBar = ({ psInfo }: actionsProps) => {
  const history = useHistory();

  return (
    <div
      slot="end"
      className="action-bar-container"
      style={{ marginLeft: "10%" }}
    >
      <i
        onClick={(e) => {
          e.preventDefault();
          if (psInfo.canResume()) {
            history.push(`/PracticeSession/Start/${psInfo.id}`);
          }
        }}
        title={
          psInfo.canResume() ? "Resume this practice session" : "Completed"
        }
        className={`fa fa-play font-size-icon clickable-icon ${
          psInfo.canResume() ? "text-danger" : "text-disabled"
        }`}
        id={`start_practice_session_${psInfo.id}`}
        style={{
          display: psInfo.canResume() ? "inline" : "none",
          margin: "auto",
        }}
      ></i>

      <div
        onClick={() =>
          window.location.assign(`/PracticeSession/Summary/${psInfo.id}`)
        }
        id={`practice_session_summary_${psInfo.id}`}
        style={{
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Icon
          path={mdiViewDashboard}
          size={0.7}
          title="Show summary"
          vertical
          color="black"
        />
      </div>

      <i
        onClick={() => {
          if (psInfo.canCreateNewPracticeFrom()) {
            window.location.assign(`/PracticeSession/CreateFrom/${psInfo.id}`);
          }
        }}
        title="Create new practice from this."
        className={`fa fa-copy font-size-icon clickable-icon ${
          psInfo.canCreateNewPracticeFrom() ? "text-success" : "text-disabled"
        }`}
        id={`dup_session_${psInfo.id}`}
        style={{
          display: psInfo.canCreateNewPracticeFrom() ? "inline" : "none",
          margin: "auto",
        }}
      ></i>
    </div>
  );
};

type PracticeSessionListComponentProps = {
  darkMode: boolean;
  psList: PracticeSessionListInfoItem[];
  onPracticeCreated: () => Promise<void>; // Indicates that this function is asynchronous and returns a promise
};

export const PracticeSessionListComponent = ({
  darkMode,
  psList,
  onPracticeCreated,
}: PracticeSessionListComponentProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayedData, setDisplayedData] = useState<
    PracticeSessionListInfoItem[]
  >([]);

  const headerColumns = [
    { size: "8", sizeMd: "2", label: "Name", className: "header-cell" },
    {
      size: "12",
      sizeMd: "2",
      label: "Exams",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Topic",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Wordlist",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "2",
      label: " Expected Questions",
      className: "ion-text-center ion-hide-md-down header-cell",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Expected Words",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Total Expected Questions",
      className: "ion-text-center ion-hide-md-down header-cell",
    },
    {
      size: "4",
      sizeMd: "1",
      label: "Actions",
      className: "ion-text-center header-cell",
    },
  ];

  useEffect(() => {
    const initialData = psList.slice(0, rowsPerPage);
    setDisplayedData(initialData);
  }, [psList, rowsPerPage]);

  useEffect(() => {
    setDisplayedData(psList.slice(0, rowsPerPage)); // Fully reset the data
    setCurrentPage(1); // Reset pagination to the first page
  }, [psList]);

  useEffect(() => {
    updateDisplayedData(currentPage, rowsPerPage);
  }, [psList]);

  const updateDisplayedData = (newPage: number, newRowsPerPage: number) => {
    const startIndex = (newPage - 1) * newRowsPerPage;
    const endIndex = startIndex + newRowsPerPage;
    const newData = psList.slice(startIndex, endIndex);
    setDisplayedData(newData);
    setCurrentPage(newPage);
  };

  const onPageChange = (newPage: number) => {
    updateDisplayedData(newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    updateDisplayedData(1, newRowsPerPage);
  };

  const renderIonCols = (
    psInfo: PracticeSessionListInfoItem,
    index: number
  ) => {
    const ionCols = [
      { size: "8", sizeMd: "2", content: psInfo.name },
      {
        size: "12",
        sizeMd: "2",
        content: psInfo.examNames,
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "1",
        content: psInfo.topicName,
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "1",
        content: psInfo.wordlistName || "N/A",
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "2",
        content: psInfo.totalQuestions - psInfo.totalWordQuestions,
        className: "ion-text-center ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "1",
        content: psInfo.totalWordQuestions,
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "1",
        content: psInfo.totalQuestions,
        className: "ion-text-center ion-hide-md-down",
      },
      {
        size: "4",
        sizeMd: "1",
        content: <ActionBar psInfo={psInfo} />,
        className: "ion-text-center custom-action-bar",
      },
    ];

    return ionCols.map((col, colIndex) => {
      const isEvenRow = index % 2 !== 0;
      const isCustomWidthColumn = [3, 4].includes(colIndex);
      return (
        <IonCol
          key={index}
          size={isCustomWidthColumn ? "auto" : col.size}
          size-md={isCustomWidthColumn ? "auto" : col.sizeMd}
          className={`${col.className} header-cell bordered-cell custom-col ${
            isEvenRow ? "even-row-background" : "odd-row-background"
          }`}
          style={{
            fontFamily: "Roboto, sans-serif",
            ...(darkMode ? { background: "#1f1f1f", color: "white" } : {}),
          }}
        >
          <div style={{ width: isCustomWidthColumn ? "80px" : "" }}>
            {col.content}
          </div>
        </IonCol>
      );
    });
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>My Practices</IonTitle>
          {psList.length != 0 && (
            <IonButton
              className="button-text-normal"
              color="primary"
              size="small"
              slot="end"
              fill="solid"
              onClick={openModal}
            >
              New Practice
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      {psList.length ? (
        <IonContent>
          <IonGrid className="custom-grid large-screen">
            <IonRow className="row-header">
              {headerColumns.map((column, index) => {
                const isCustomWidthColumn = [3, 4].includes(index);

                return (
                  <IonCol
                    key={index}
                    size={isCustomWidthColumn ? "auto" : column.size}
                    size-md={isCustomWidthColumn ? "auto" : column.sizeMd}
                    className={`${column.className} header-cell custom-header-cell`}
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      ...(darkMode
                        ? { background: "#1f1f1f", color: "white" }
                        : {}),
                    }}
                  >
                    <div style={{ width: isCustomWidthColumn ? "80px" : "" }}>
                      {column.label}
                    </div>{" "}
                  </IonCol>
                );
              })}
            </IonRow>

            {displayedData.map(
              (psInfo: PracticeSessionListInfoItem, index: number) => (
                <IonRow key={psInfo.id} className="custom-data-row">
                  {renderIonCols(psInfo, index)}
                </IonRow>
              )
            )}
          </IonGrid>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(psList.length / rowsPerPage)}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            darkMode={darkMode}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
          />
        </IonContent>
      ) : (
        <IonContent>
          <div className={darkMode ? "custom-alert-dark" : "custom-alert"}>
            <div>
              <h4 className={darkMode ? "alert-text-white" : ""}>
                Looks like you have not created any practice yet!
              </h4>
              <p className={darkMode ? "alert-text-white" : ""}>
                Do you want to create a new practice?
              </p>
              <IonButton
                className={darkMode ? "alert-text-white" : "button-text-normal"}
                color="primary"
                size="small"
                slot="end"
                fill="solid"
                onClick={openModal}
              >
                New Practice
              </IonButton>
            </div>
          </div>
        </IonContent>
      )}
      {isModalOpen && (
        <CreatePracticeForm
          closeModal={closeModal}
          onPracticeCreated={onPracticeCreated}
        />
      )}
    </>
  );
};
